<!--
 * @Description: AKJERRT
 * @Date: 2022-08-04 11:12:55
 * @LastEditTime: 2022-08-04 13:59:25
 * @FilePath: \linkgap-front\src\views\system\BaseSetting.vue
-->
<template>
  <div>
    <a-card>
      <div class="order_info">
        <a-divider orientation="left">订单设置</a-divider>
        <div class="set">
          <div>存在逾期额度允许生成发货单</div>
          <span @click.stop="">
            <span>{{ flagLimitOverdueOrder ? '启用' : '禁用' }}&emsp;</span>
            <span v-if="check">
              <a-switch
                v-model="flagLimitOverdueOrder"
                default-checked
                @change="onChange(flagLimitOverdueOrder, 'flagLimitOverdueOrder')"
              />
            </span>
          </span>
        </div>
      </div>
      <div class="order_info">
        <a-divider orientation="left">对账单设置</a-divider>
        <div class="set">
          <div>确认对账单上传确认文件</div>
          <span @click.stop="">
            <span>{{ flagUploadConfirmFile ? '启用' : '禁用' }}&emsp;</span>
            <span v-if="check">
              <a-switch
                v-model="flagUploadConfirmFile"
                default-checked
                @change="onChange(flagUploadConfirmFile, 'flagUploadConfirmFile')"
            /></span>
          </span>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { checkPermission } from '@/utils/permissions'
export default {
  data() {
    return {
      init_data: {
        id: '',
      },
      init_data2: {
        id: '',
      },
      flagLimitOverdueOrder: null,
      flagUploadConfirmFile: null,
      check: checkPermission('system:setting:update'),
    }
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    onChange(data, type) {
      if (type === 'flagLimitOverdueOrder') {
        data ? (this.init_data.flagLimitOverdueOrder = 1) : (this.init_data.flagLimitOverdueOrder = 0)
        this.getSettingEdit(this.init_data.flagLimitOverdueOrder)
      }
      if (type === 'flagUploadConfirmFile') {
        data ? (this.init_data2.flagUploadConfirmFile = 1) : (this.init_data2.flagUploadConfirmFile = 0)
        this.getSettingEdit2(this.init_data2.flagUploadConfirmFile)
      }
    },
    getSettingEdit() {
      this.axios.post(`/api/base/sysSetting/edit`, this.init_data).then((res) => {
        console.log(res)
      })
    },
    getSettingEdit2() {
      this.axios.post(`/api/base/sysSetting/edit`, this.init_data2).then((res) => {
        console.log(res)
      })
    },
    getSetting() {
      this.axios.get(`/api/base/sysSetting/get`).then(({ body, code }) => {
        if (code === 200) {
          this.flagLimitOverdueOrder = body.flagLimitOverdueOrder
          this.flagUploadConfirmFile = body.flagUploadConfirmFile
          this.init_data.id = body.id
          this.init_data2.id = body.id
          console.log(body)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.footer-bts {
  text-align: center;
}
.set {
  margin-top: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
</style>
